import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import TreatmentHero from "../../components/treatment-hero";
import ReviewSection from "../../components/review-section";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import BenefitsListComponent from "../../components/benefits-list-component";
import TreatmentCardFacial from "../../components/facial-treatment-card";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";
import uuid4 from "uuid4";

const PolynucleotideBiostimulatingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
      }
      schemaLogo: wpMediaItem(title: { eq: "dark-logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "polynucleotide-bio-stimulating" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        polynucleotidePageFieldGroups {
          polynucleotideIntroSection {
            title
            heading
            subHeading
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          polynucleotideReviewSection {
            heading
            subHeading
          }
          polynucleotideTextSection
          polynucleotideTwoColumnWithCtaSection {
            content
            heading
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          polynucleotideWhatIsInvolvedSection {
            heading
            items {
              heading
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
              cta {
                title
                target
                url
              }
              treatmentItems {
                cost
                extraText
                effects
                recovery
                results
                treatmentTime
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, polynucleotidePageFieldGroups, slug },
  } = data;

  const {
    polynucleotideIntroSection,
    polynucleotideTextSection,
    polynucleotideReviewSection,
    polynucleotideTwoColumnWithCtaSection,
    polynucleotideWhatIsInvolvedSection,
  } = polynucleotidePageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id":
      `https://www.dentalhouseexeter.co.uk/treatments/${slug}/#${slug}`,
    image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
    sku: `https://www.dentalhouseexeter.co.uk/treatments/${slug}`,
    description: `${seoFieldGroups?.metaDescription}`,
    logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
    name: "Dental House Exeter",
    url: "https://www.dentalhouseexeter.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.dentalhouseexeter.co.uk${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.dentalhouseexeter.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Dental House Exeter",
      url: "https://www.dentalhouseexeter.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "5",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.dentalhouseexeter.co.uk/reviews",
      datePublished: "2022-03-21T17:58:29+00:00",
      reviewBody:
        "This is the best dental practice I have known. Not only in terms of expertise but the lovely sociability of every single member of the practice team.",
      author: {
        "@type": "Person",
        name: "Caroline P",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Dental House Exeter",
        sameAs: "https://www.dentalhouseexeter.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Treatments",
        item: {
          url: `${siteUrl}/treatments`,
          id: `${siteUrl}/treatments`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Polynucleotide Bio-stimulating",
        item: {
          url: `${siteUrl}/treatments/polynucleotide-bio-stimulating`,
          id: `${siteUrl}/treatments/polynucleotide-bio-stimulating`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/polynucleotide-bio-stimulating`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {polynucleotideIntroSection &&
          !checkPropertiesForNull(polynucleotideIntroSection, ["heading"]) && (
            <TreatmentHero
              noSmallHeading
              objPos="right top"
              img={
                polynucleotideIntroSection?.image.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
              imgAlt={polynucleotideIntroSection?.image.node?.altText}
              heading1={polynucleotideIntroSection?.title}
              headingSmall={polynucleotideIntroSection?.subHeading}
              heading2={polynucleotideIntroSection?.heading}
              text={
                <SafeHtmlParser
                  htmlContent={polynucleotideIntroSection?.description}
                />
              }
              ctaButton={polynucleotideIntroSection?.cta}
            />
          )}
        {polynucleotideTextSection && (
          <SafeHtmlParser htmlContent={polynucleotideTextSection} />
        )}

        <ReviewSection
          heading={
            polynucleotideReviewSection?.heading || "Reviews From Our Patients"
          }
          smallheading={
            polynucleotideReviewSection?.subHeading ||
            "WHAT PEOPLE SAY ABOUT US"
          }
          page="Veneers and bonding"
        />
        {polynucleotideTwoColumnWithCtaSection &&
          !checkPropertiesForNull(polynucleotideTwoColumnWithCtaSection, [
            "heading",
          ]) && (
            <BenefitsListComponent
              img={
                polynucleotideTwoColumnWithCtaSection?.image.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
        
              imgHeight="50rem"
              imgAlt={
                polynucleotideTwoColumnWithCtaSection.image.node?.altText
              }
              text={
                <div>
                  <h2 className=" text-center text-lg-start display-4 pb-5">
                    {polynucleotideTwoColumnWithCtaSection?.heading}
                  </h2>
                  <GatsbyImage
                    className="mb-5 w-100 d-lg-none"
                    image={
                      polynucleotideTwoColumnWithCtaSection?.image.node
                        ?.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={
                      polynucleotideTwoColumnWithCtaSection.image.node
                        ?.altText
                    }
                  />

                  <SafeHtmlParser
                    htmlContent={
                      polynucleotideTwoColumnWithCtaSection?.content
                    }
                  />
                </div>
              }
              ctaButton={polynucleotideTwoColumnWithCtaSection?.cta}
            />
          )}
       
       {polynucleotideWhatIsInvolvedSection &&
          !checkPropertiesForNull(polynucleotideWhatIsInvolvedSection, [
            "heading",
          ]) && (
            <section className=" py-5">
              <Container>
                <Row>
                  <Col className="text-center">
                    <h2 className=" display-5 pb-4">
                      {polynucleotideWhatIsInvolvedSection?.heading}
                    </h2>
                  </Col>
                </Row>
                <Row className=" pt-5 h-100 justify-content-center">
                  {polynucleotideWhatIsInvolvedSection.items.map((cardItem) => (
                    <TreatmentCardFacial
                      key={uuid4()}
                      image={
                        cardItem?.image.node?.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      imgAlt={cardItem?.image?.node?.altText}
                      title={cardItem?.heading}
                      extraText={cardItem?.treatmentItems.extraText}
                      tTime={cardItem?.treatmentItems.treatmentTime}
                      recovery={cardItem?.treatmentItems.recovery}
                      effects={cardItem?.treatmentItems.effects}
                      cost={cardItem?.treatmentItems.cost}
                      results={cardItem?.treatmentItems.results}
                      span={4}
                      url={cardItem?.cta.url}
                      linkText={cardItem?.cta.title}
                      
                    />
                  ))}
                </Row>
              </Container>
            </section>
          )}

        {/* <WordpressFaq page="Polynucleotide Bio-stimulating" /> */}
        {/* <TreatmentImageSection
					img={wc1Img}
					imgAlt1={data.wc1Img?.altText}
					img2={wc2Img}
					imgAlt2={data.wc2Img?.altText}
					img3={wc3Img}
					imgAlt3={data.wc3Img?.altText}
					heading="Before and after Polynucleotide"
					headingSmall="Gallery"
				/> */}
      </Layout>
    </>
  );
};

export default PolynucleotideBiostimulatingPage;
